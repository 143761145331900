import React from "react";
import Layout from "../components/Layout";
import styled from "styled-components";
import { graphql } from 'gatsby'
import Img from '../components/PreviewCompatibleImage'
import Seo from '../components/Seo'
const Article = styled.article`

`

const Quote = styled.p`
  padding-left: 2rem;
  @media (max-width: 768px) {
    padding-left: 1rem;
  }
`

const Caption = styled.span`
  display: inline-block;
  text-align: right;
  padding: 1rem 4rem;
  max-width: calc(1440px + 4rem);
  background-color: #e4e2e2;
  width: 100%;
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const Direction = styled.span`
  > :first-child {
    display: none;
  }
  @media (max-width: 768px) {
    > :first-child {
      display: inline;
    }
    > :last-child {
      display: none
    }
  }

`;
const LeadText = styled.h2`
  font-size: 1.5rem;
  max-width: 60ch;
  font-weight: 500;
  line-height: 1.2;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const BodyText = styled.div`
  max-width: 800px;
  margin: 4rem auto;
  @media (max-width: 768px) {
    margin: 2rem auto;
  }
`;

const ImageHolder = styled.div`
  padding: 6rem 0;
  display: flex;
  justify-content: center;

  > *:first-child {
    padding-right: 1rem;
  }
  >*:last-child {
    padding-left: 1rem;
  }
  > *:only-child {
    padding: 0;
  }
  @media (max-width: 768px) {
    padding: 4rem 0 0;
    flex-direction: column;
    justify-content: center;
    > *:first-child {
      padding-right: 0;
      padding-bottom: 0.5rem;
    }
    >*:last-child {
      padding-left: 0;
      padding-top: 0.5rem;
    }
    > *:only-child {
      padding: 0;
    }
  }
`;

const PageWrapper = styled.div`
  padding: 1rem 4rem;
  max-width: calc(1440px + 4rem);
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

const DarkBg = styled.div`
  background-color: #e4e2e2;
`;

const BioPage = ({data}) => {

  const imgProps = { 
    style: { maxHeight: 'unset'},
    imgStyle: {objectFit: 'contain'},
  }
  return (
    <>
      <Seo title="Nigel Lendon - Biography" description="A short biography of Nigel's life, career, contemporary work and research."/>
      <Layout noPadding>
        <Article>
          <PageWrapper>
            <LeadText>
              Born in Adelaide in 1944, Nigel Lendon occupies a unique position in the Australian contemporary arts scene as a artist, writer, educator and curator.
            </LeadText>
            <BodyText>
              <p>
                His ancestry is Anglo-Norman.  His grandfather, Alfred Austin Lendon, was a North Terrace GP who came to South Australia in 1883 and became a member of the circle of scientists and historians associated with the South Australian Museum. Nigel’s father, Alan Harding Lendon, was Professor of Surgery at Adelaide University, which partly explains why Nigel attended medical school before transferring his studies to the fine arts. He was expected by his family to follow in his father's footsteps. He grew up in a house with no art on the walls, save for an amateurish landscape above the fireplace, and his father’s collection of Gould prints and illustrated books of Australian parrots. His only other visual stimulus as a child was a generous Afghan tribal rug in the hallway.
              </p>
              <p>
                Lendon’s introduction to the world of art was at the Art Gallery of South Australia, adjacent to the University. But it was elsewhere at University that Lendon discovered contemporary art and literature, initially through New Wave French cinema. The films of Alain Resnais, Jean Luc Godard, plus the literature of Albert Camus and others, transformed his understanding of what art could be. In 1964 he abandoned medical school for the South Australian School of Art and encountered and connected with influential teachers and colleagues including Bill Gregory, Bill Clements, Owen Broughton, Tony and Olive Bishop (whose Hackney studio he shared), Robert Boynes, Aleks Danko and many others. 
              </p>
              <p>
                Lendon first exhibited his work in the 1967 Mildura Art Prize, followed by a two person show with Margaret Worth at the Contemporary Art Society (CAS), plus other group exhibitions in the years that followed. The first works he sold were a suite of grid drawings, bought by fellow <b>The Field</b> artist Alun Leach Jones, followed by Kym Bonython, who acquired a work (now in the AGSA) from his exhibition at the CAS. 
              </p>
            </BodyText>
          </PageWrapper>
          <DarkBg>
            <PageWrapper>
              <ImageHolder>
                <div style={{flex: 1}}>
                  <Img {...imgProps} imageInfo={{image: data.img1}}/>
                </div>
                <div style={{flex: 1}}>
                  <Img {...imgProps} imageInfo={{image: data.img2}}/>
                </div>
              </ImageHolder>

            </PageWrapper>
            <Caption><Direction> <span>(first)</span> <span>(left)</span></Direction> Nigel Lendon at Bonython Gallery, 1969.<br/> <Direction> <span>(second)</span> <span>(right)</span></Direction> Lendon, circa 1972 at the Gippsland Institute of Advanced Education.</Caption>
          </DarkBg>
          <PageWrapper>
            <BodyText>
              <p>
                In 1968 Lendon was one of the younger artists included in the National Gallery of Victoria's inaugural exhibition <b>The Field</b> where he first saw the work of Mel Ramsden and Ian Burn, who were later to become friends and colleagues in the international conceptual art group, Art and Language.
              </p>
              <p>
                <a href="https://youtu.be/7dSeM1DgMNk">[Video] Nigel Lendon being interviewed by Raymonda Rajkowski for <b>Return to the Field</b>, 2018</a>
              </p>
              <p>In 1986 Daniel Thomas, then Director of the Art Gallery of South Australia, in a review of contemporary art in Adelaide, identified Lendon as the first Australian Minimalist artist. Thomas wrote: </p>
              <Quote>
                Art in Adelaide in the 1960s seemed, to an observer from Sydney, more progressive, more in touch with international developments, than art in Melbourne or Sydney. That really meant more American. Charles Redington from Chicago was teaching in Adelaide in the early 1960s and his abstract expressionist paintings were the first in Australia to look American. Then in 1965 Sydney Ball came home to Adelaide from New York to paint Australia's first plausible hard edged abstractions and Stanislaw Ostoja-Kotkowski’s op-art paintings, laser art, and other art and technology works, though more part of our traditional Anglo French cultural connection, were the most advanced of their kind in Australia. In 1967, at the Mildura Triennial exhibition of sculpture, the Adelaide art student Nigel Lendon showed Australia's first specimen of Minimal art.
                <span> (Foreword, AGSA catalogue <i>Skangaroovian Funk</i>, 1986)</span>
              </Quote>
              <p>
                In 1972, together with the painter and author Robin Wallace-Crabbe, Lendon was recruited to the faculty at the new Gippsland Institute of Advanced Education to teach sculpture, where they introduced a then radical individual-oriented curriculum. In the same year, he was appointed to the newly constituted Visual Arts Board of the Australia Council where, together with Udo Sellbach and Geoff Parr, he actively contributed to the new ideas that were forming in relation to visual arts education in the emerging University research-oriented context.
              </p>

              <p>Through the early 1970s, as well as participating in numerous group exhibitions, (for example, <b>The Situation Now</b>, curated by Terry Smith in Sydney in 1971), Lendon also held two solo exhibitions firstly at the Bonython Art Gallery in Sydney, and later the same year at Watters Gallery. In the Watters exhibition he created one of the first installation works in Australia, a work that completely filled the architect Don Gazzard’s dramatic Brutalist gallery interior with a complex perspectival structure constructed solely of industrial scaffolding. </p>
              <p>From these years, his work was collected by the National Gallery of Australia, the Art Gallery of South Australia, the Art Gallery of New South Wales and several regional galleries. His new work was included in several key group exhibitions, firstly the exhibition <b>I want to leave a nice well-done child here</b>, curated by the Kaldor Public Art Projects guest curator, Harald Szeemann at the National Gallery of Victoria in 1971, followed by <i>Object and Idea</i> at the NGV in 1973.</p>
              <p>In 1974 Lendon was awarded a Harkness Fellowship which enabled him to live, work and study in New York for the next two years. He first took classes with Meyer Shapiro at Columbia University and then became a Fellow at the just-formed Art and Language Institute. The conceptual art group A&L was in its expansionist phase, producing both the journal The Fox, and together with Mayo Thompson and The Red Crayola, a series of Music-Language productions. One of the outcomes of Music Language was their participation in the Aperto section of the Venice Biennale early in 1976, where Art and Language performed and projected the video work <a href="https://www.youtube.com/watch?v=gSJ7XwawZOc">Nine Gross and Conspicuous Errors</a> to a bemused Italian audience. </p>
              <p>While in New York Lendon became friends with Weston Naef, then Curator of Photography at the Metropolitan Museum, with whom he shared an interest in Eadweard Muybridge’s landscape photography, and the 19th century phenomenon of stereoscopic photography.  Stereo vision, theories of perspective and the creation of pictorial space are themes that Lendon has explored throughout his work, and indeed perspective as a metaphor for the social relations implicit in a work of art has been an idea that has recurred throughout his career, both in his works of art and related texts. Since his formative experiences in New York, photography has also been a recurring subject of Lendon’s published essays. </p>
              <p>When Lendon returned to Australia in 1976 he participated in the formation of a number of key organizations in Sydney that were forming around Art and Language and the related interest groups Media Action Group, Union Media Services, The Artworkers Union, National Association for the Visual Arts, and the Arts Law Centre.</p>

            </BodyText>
          </PageWrapper>
          <DarkBg>
            <PageWrapper >
              <ImageHolder>
                <div style={{flex: 1}}>
                  <Img {...imgProps} imageInfo={{image: data.img4}}/>
                </div>
                <div style={{flex: 1}}>
                  <Img {...imgProps} imageInfo={{image: data.img5}}/>
                </div>
              </ImageHolder>
            </PageWrapper>
            <Caption><Direction> <span>(first)</span> <span>(left)</span></Direction> Members of the Art Workers Union, 1979. Lendon is seated second from right, with Pete the Dog.<br/>  <Direction><span>(second)</span> <span>(right)</span></Direction> Ingo Kleinert and Nigel Lendon, 1995, installing Lendon’s ‘Untitled Invisible Sculpture’ at the ANU’s Drill Hall Gallery.</Caption>
          </DarkBg>
          <PageWrapper>
            <BodyText>
              <p>Also in these years, Lendon was recruited to the Art Theory Department at the new Sydney College of the Arts, where he was responsible for the development of its postgraduate programs. In 1988, Lendon moved to Canberra to take up the position of Deputy Director of the then Canberra School of Art, where his primary role was as Convener of the graduate program. This led to the introduction of research degrees in the Visual Arts and eventually the PhD program once the School of Art formally became part of the Australian National University.</p>
              <p>From 1980 a significant aspect of Lendon’s practice has been his art historical and theoretical texts, of which perhaps the most influential was <i>The Necessity of Australian Art: An Essay about Interpretation</i> co-authored with Ian Burn, Charles Merewether and Ann Stephen in 1988. His contribution to this text was to investigate the advent of a concept of the avant-garde in Australian art history and criticism.</p>
              <p>Lendon’s curatorial practice coincided with his growing interest in Indigenous art, which in conjunction with Wally Caruana and Djon Mundine, culminated in the National Gallery of Australia’s exhibition of the work of <b>The Painters of the Wagilag Sisters Story 1937 to 1997</b>, for which Lendon was the lead researcher and curator. This was the first exhibition of its kind, searching world collections to assemble a 60-year art history through the successive generations of the artists of Arnhem Land who had held the rights to paint this complex creation narrative.</p>
              <p>In his review of the Wagilag Sisters exhibition, the eminent historian Greg Dening wrote:</p>
              <Quote>
                  For seven years this coming together of paintings, artists and ritual custodians had been negotiated. The occasion was the unprecedented exhibition, <b>The Painters of the Wagilag Sisters Story 1937-1997</b>. One hundred bark paintings and sculptures by 29 artists of the different Clans of Central and Eastern Arnhem Land. They spanned four generations, the oldest of the artists was born in 1891. The brilliance of The Painters of the Wagilag Sisters Story exhibition was how it represented the unchanging authority of the artists to tell the story, and preserved the individual theatricality of the artists. These Yolngu painters had no difficulty in being timeless and precisely timed in the one living moment. Nigel Lendon (with Wally Caruana) have crowned years of sensitive dialogue and careful curatorship to produce the exhibition… In my estimation it was a wonderful example for us to take into the 43rd millennium of the shared custodianship of our common heritage.
                  <span>(Greg Dening, ‘Time Searchers’, <i>The Australian</i>, 11-13 August 1999)</span>
              </Quote>
              <p>Lendon’s time in Arnhem Land opened the door to further cross-cultural studies, this time focussed on Afghanistan, together with his friend and ANU colleague Tim Bonyhady. While a fellow at the university’s Centre for Cross Cultural Research Lendon was shown examples of the Afghan “war rug” by his Adelaide friends, Olive and Tony Bishop, who in turn, had alerted the Sydney art dealer Ray Hughes to their discovery. It was Hughes who subsequently introduced the “war rug” to the Sydney art world.  </p>
              <p>In 2002 Lendon and Bonyhady curated a small exhibition of war rugs shown at the Canberra School of Art which toured to the Adelaide Festival of Arts in 2004, accompanied by one of the first scholarly publications on this subject. Of the back of this initial exhibition and publication, Lendon— the first ANU academic to be funded to produce a blog as a research tool—created the Rugs of War blog, which in turn led to an ARC Discovery Project with Bonyhady titled <a href="https://rugsofwar.wordpress.com/">The Rugs of War</a>. This major research project identified some 20 international private collections and led to Lendon’s period of fieldwork in Iran and Afghanistan. It also resulted in a number of conference papers and publications.</p>
            </BodyText>
          </PageWrapper>
          <DarkBg>
            <PageWrapper>
              <ImageHolder>
                <div style={{flex: 0.6}}>
                  <Img {...imgProps} imageInfo={{image: data.img6}}/>
                </div>
              </ImageHolder>
            </PageWrapper>
          </DarkBg>
          <Caption>Lendon being photographed by a street photographer in Herat, Afghanistan, 2007.</Caption>
          <PageWrapper>
            <BodyText>
              <p>Following his retirement from the ANU in 2012, Lendon has returned to full-time studio practice, while continuing his collaborations, writing, and participating in a number of survey exhibitions including <b><a href="https://www.heide.com.au/exhibitions/less-more-minimal-and-post-minimal-art-australia">Less is More: Minimal and Post-Minimal Art in Australia</a></b> at the Heidi Museum and Gallery in 2012 and <b><a href="http://www.sno.org.au/sno-exhibitions-100-199#/156">IN Formalism</a></b> curated by Sydney Non-Objective at the Casula Powerhouse in 2018. In 2017 his studio practice culminated in a series of exhibitions titled <b>Modelling the Now</b> at the ANU Drill Hall Gallery, Charles Nodrum Gallery in Melbourne, and Milani Gallery in Brisbane.</p>
            </BodyText>
          </PageWrapper>
          <DarkBg>
            <PageWrapper >
              <ImageHolder>
                <div style={{flex: 0.6}}>
                  <Img {...imgProps} imageInfo={{image: data.img7}}/>
                </div>
              </ImageHolder>
            </PageWrapper>
            <Caption> Nigel Lendon at work in his Wamboin studio ‘RKD’, 2016.</Caption>
          </DarkBg>
          <PageWrapper>
            <BodyText>
              <p>As a consequence of his research on the rugmakers of Afghanistan, Lendon has theorised and published extensively about collaboration and collective (relational) agency more widely. At the same time in relation to his artistic practice he has participated in a number of collaborative arts projects, which range from the production of works for other artists as a kind of proxy-agent (notably with Mel Ramsden) to collaborative projects with other artists, including Lucina Lane at CAVES in Melbourne in 2018, and Emma Beer at The Canberra Museum and Art Gallery in 2020. In addition, he has curated a series of exhibitions in his studio gallery at Wamboin, <i>RKD</i>, which are documented in his blog <a href="https://catalogue.nla.gov.au/Record/4808888?lookfor=nigel%2520lendon%2520iconophilia&offset=1&max=1" >Iconophilia</a> (archived at the Australian National Library).</p>
              <p>Lendon’s most recent 2020 studio work has focussed on a forthcoming joint exhibition with Aleks Danko, initially at Milani Gallery, Brisbane, which explores common themes that have arisen in their work since their art school days in Adelaide.</p>
              <p>Lendon is a Fellow of both the NGA and the QAGOMA Foundations. He is married to the anthropologist Pamela Faye McGrath.</p>
            </BodyText>
            <BodyText>
              <p>---</p>
              <p>Nigel died on the 24th of October 2021 after battling brain cancer (GBM4) for over a year.</p>
              <p>He is survived by his wife Pamela McGrath, his son Axel Debenham-Lendon and brother Colin Lendon.</p>
              <p>Nigels most recent works will be exhibited along side work by Alek's Danko at Milani Gallery Brisbane in <i>NOT A TITLE</i> on February 2022.</p>

              <p>Obituaries
                <br/>
                <a rel="external" href="https://fass-comms.sydney.edu.au/pub/pubType/EO/pubID/zzzz618217c1e47d4482/?aid=6a4f5f323f263faa&#6a4f5f323f263faa">Sydney University</a> - Robyn Backen <br/>
                <a rel="external" href="https://www.artlink.com.au/articles/4955/vale-nigel-lendon-1944E280932021/">Artlink magazine</a> - Ruark Lewis<br/>
                <a rel="external" href="https://arthistorynetwork.net.au/2021/10/29/vale-nigel-lendon/">Art History Network</a> - Mark Shepard<br/>
                <a href="/david-williams-obituary">Canberra Times</a> - David Williams
              </p>
              <p>Extras
                <br/>
              <a rel="external" href="https://www.habitusliving.com/design-hunters/design-hunter-qa-with-nigel-lendon">Design Hunter Interview</a> - a fantastic insight into Nigel's taste and character.</p>
            </BodyText>
          </PageWrapper>
        </Article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query BioImages {
    img1: file(relativePath: { eq: "1969_lendon_at_bonython_gallery.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "1973_Nigel Lendon circa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "1976_The_Fox_3_Cover_Page.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "1979_art_workers_union.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "1995_lendon_and_kleinert.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "2007_herat.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "nigel_studio_portrait_2016.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default BioPage;
